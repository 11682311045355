.nav-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  mix-blend-mode: difference;
}

.nav-content {
  padding: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 150rem;
  margin: 0 auto;
  height: max-content;
}

.nav-content-logo p {
  font-size: 5.5rem;
  color: #fff;
  font-weight: 900;
}

.nav-content-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.5rem;
}

.nav-content-menu a {
  position: relative;
  font-size: 2rem;
  color: #fff;
  font-weight: 400;
  text-transform: lowercase;
}

.nav-content-menu a:hover {
  color: rgb(0, 156, 156);
}

.hamburger-menu {
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 4px;
  cursor: pointer;
}

.hamburger-menu span {
  width: 20px;
  height: 2px;
  background-color: #fff;
}

.mobileNav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 99;
  transform: translateX(100%);
  transition: transform 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  overflow: hidden;
  font-family: var(--main-font);
}

.mobileNav-container-videoBg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #000;
  opacity: 1;
}

.mobileNav-container-videoBg video {
  filter: blur(10px);
}

.mobileNav-container.navMobile-active {
  transform: translateX(0%);
  transition: transform 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.mobileNav-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 25rem;
  padding: 0rem 4rem;
  overflow: hidden;
}

.mobileNav-content-menu {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  line-height: .9;
  height: 100%;
}

.mobileNav-content-menu-item a {
  font-size: 6rem;
  color: transparent;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
}

.mobileNav-line {
  height: 30rem;
  width: 1px;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  opacity: 1;
}

.mobileNav-socialMedia-container {
  position: absolute;
  bottom: 10%;
  right: 10%;
}

.mobileNav-socialMedia-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
}

.mobileNav-socialMedia-item a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  gap: 10px;
}

.mobileNav-socialMedia-item a h1 {
  font-size: 1.4rem;
  color: #000;
  font-weight: 300;
}


.mobileNav-socialMedia-item img {
  width: 3.5rem;
  height: 3.5rem;
  object-fit: cover;
}

.mobileNav-underText {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 2rem 0rem;
}


.mobileNav-underText-content {
  display: flex;
  white-space: nowrap;
  animation: marquee 15s linear infinite;
}

.mobileNav-underText-content h1 {
  text-transform: uppercase;
  font-weight: 900;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (min-width: 1921px) {
 .nav-content {
  max-width: 200rem;
 } 

  .nav-content-logo p {
    font-size: 7.5rem;
  }

  .nav-content-menu a {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 1440px) {
  .nav-content {
    max-width: 120rem;
  }
  .nav-content-logo p{
    font-size: 5.5rem;
  }
  .nav-content-menu a{
    font-size:1.8rem;
  }
}

@media screen and (max-width: 1024px) {
  .nav-content {
    max-width: 90rem;
  }
}

@media screen and (max-width: 768px) {
  .nav-container {
    font-family: var(--mobile-font);
  }

  .mobileNav-container {
    font-family: var(--main-font);
  }

  .hamburger-menu {
    display: flex;
  }

  .nav-content-menu {
    display: none;
  }

  .nav-content-logo p {
    font-size: 5.5rem;
    color: #fff;
    font-weight: 900;
  }
}

@media screen and (max-width: 425px) {
  html{
    font-size: 8.5px;
  }
  
  .hamburger-menu {
    display: flex;
  }

  .nav-content-menu {
    display: none;
  }
  
  .mobileNav-content  {
    margin-top: 20rem;
  }

  .nav-content-logo p {
    font-size: 6.5rem;
  }

  .mobileNav-content-menu-item a {
    font-size: 6rem;
    font-stretch: ultra-condensed;
    font-weight: 900;
  }

  .mobileNav-content-menu {
    line-height: 0.9;
  }

  .mobileNav-line {
    height: 25rem;
  }

  .mobileNav-socialMedia-container {
    bottom: 15%;
    right: 15%;
  }

  .mobileNav-socialMedia-item img {
    width: 3rem;
    height: 3rem;
  }

  .mobileNav-underText-content h1 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 300px) {
  html{
    font-size: 7px;
  }
  
}
