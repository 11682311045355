.footer-container {
  width: 100%;
  height: 100vh;
  background-color: #000;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.footer-upper-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15rem 8rem 0rem 8rem;
  margin: 0 auto;
}

.footer-title h1 {
  letter-spacing: -0.05em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12rem;
  font-weight: 100;
  line-height: 100%;
  color: #fff;
}

.footer-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22rem;
  height: 22rem;
  background-color: #fff;
  border-radius: 50%;
}

.footer-circle::after {
  content: "";
  position: absolute;
  width: 23rem;
  height: 23rem;
  border-radius: 50%;
  border: 1px dashed #fff;
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.footer-circle h4 {
  letter-spacing: -0.05em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: 100;
  line-height: 100%;
}

.footer-bottom-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 15rem 8rem 0rem 8rem;
  margin: 0 auto;
  gap: 15rem;
}

.footer-bottom-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 20rem;
}

.footer-bottom-col-title h3 {
  letter-spacing: -0.05em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: 100;
  line-height: 100%;
  color: #fff;
}

.footer-bottom-col-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.footer-bottom-col-content ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

.footer-bottom-col-content li {
  list-style: none;
  margin-right: 1rem;
  color: #fff;
  opacity: 0.6;
  font-size: 2rem;
}

@media screen and (min-width: 1921px) {
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20rem 0rem;
  }

  .footer-title h1 {
    font-size: 15rem;
  }

  .footer-container {
    width: 100%;
    height: 100vh;
    background-color: #000;
  }

  .footer-upper-group {
    padding: 15rem 30rem 0rem 20rem;
  }

  .footer-bottom-group {
    padding: 25rem 30rem 0rem 20rem;
  }

  .footer-bottom-col-title h3 {
    font-size: 3.5rem;
  }

  .footer-bottom-col-content li {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 8px;
  }

  .footer-upper-group {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12rem 4rem 0rem 4rem;
    gap: 8rem;
  }

  .footer-title h1 {
    font-size: 8rem;
  }

  .footer-bottom-group {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15rem 4rem 0rem 4rem;
    gap: 3rem;
  }
}

@media screen and (max-width: 376px) {
  .footer-container {
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  html {
    font-size: 8px;
  }

  .footer-upper-group {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12rem 4rem 0rem 4rem;
    gap: 8rem;
  }

  .footer-title h1 {
    font-size: 5rem;
  }

  .footer-bottom-group {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15rem 4rem 0rem 4rem;
    gap: 3rem;
  }
}
