@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset;
}

:root {
  --main-font: 'Instrument Sans', sans-serif;
  --mobile-font: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

body {
  font-family: var(--main-font);
}

body::before {
  animation: noise 1s steps(2) infinite;
  background-image: url(https://www.barlow.agency/images/noise.png);
  background-size: 30rem;
  background-color: #000;
  opacity: .1;
  content: "";
  height: calc(100% + 20rem);
  left: -10rem;
  pointer-events: none;
  position: fixed;
  top: -10rem;
  width: calc(100% + 20rem);
  z-index: 9999;
}

@keyframes noise {
  0% {
    transform: translate3d(0, 9rem, 0)
  }

  10% {
    transform: translate3d(-1rem, -4rem, 0)
  }

  20% {
    transform: translate3d(-8rem, 2rem, 0)
  }

  30% {
    transform: translate3d(9rem, -9rem, 0)
  }

  40% {
    transform: translate3d(-2rem, 7rem, 0)
  }

  50% {
    transform: translate3d(-9rem, -4rem, 0)
  }

  60% {
    transform: translate3d(2rem, 6rem, 0)
  }

  70% {
    transform: translate3d(7rem, -8rem, 0)
  }

  80% {
    transform: translate3d(-9rem, 1rem, 0)
  }

  90% {
    transform: translate3d(6rem, -5rem, 0)
  }

  to {
    transform: translate3d(-7rem, 0, 0)
  }
}