.home-container{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: var(--main-font);
}

.home-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  overflow: hidden;
}

.home-title-group {
  position: absolute;
  bottom: 10%;
  left: 0%;
  z-index: 2;
  text-align: left;
  width: 100%;
}

.home-title {
  margin: 0 auto;
  max-width: 150rem;
  padding: 0rem 3.5rem;
  mix-blend-mode: difference;
  
}

.home-title h1 {
  font-size: 6rem;
  font-weight: 700;
  color: #fff;
  width: 40%;
  letter-spacing: -.03em;
  line-height: .95;
  opacity: 0;
}

.home-subtitle {
  margin: 0 auto;
  max-width: 150rem;
  padding: 3rem 3.5rem;
}

.home-subtitle h4 {
  font-size: 1.6rem;
  font-weight: 100;
  color: #fff;
  text-transform: capitalize;
  width: 40%;
}

.home-scroll-bottom {
  position: absolute;
  bottom: 17%;
  right: 0%;
  width: fit-content;
  height: fit-content;
  z-index: 9;
  cursor: pointer;
}

.home-scroll-bottom-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: normal;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
  gap: 10px;
}

.scroll-bottom-line {
  width: 22rem;
  height: 1px;
  background-color: #fff;
  margin-right: 2rem;
}

.scroll-bottom-text {
  transform: translateX(10rem);
  width: fit-content;
  transition: transform .6s cubic-bezier(0.895, 0.03, 0.685, 0.22);

}

.scroll-bottom-text h4 {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 100;
}

.home-scroll-bottom-content:hover .scroll-bottom-text{
  transform: translateX(0);
  transition: transform .6s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

@media screen and (min-width: 1921px) {
  .home-subtitle {
    max-width: 200rem;
  }

  .home-subtitle h4 {
    font-size: 2.5rem;
  }

  .scroll-bottom-text h4 {
    font-size: 2rem;
  }

  .scroll-bottom-line {
    width: 25rem;
    height: 1px;
    background-color: #fff;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 1440px) {
  .home-subtitle {
    max-width: 120rem;
  }
}

@media screen and (max-width: 1024px) {
  .home-subtitle {
    max-width: 80rem;
  }
} 
@media screen and (max-width: 768px) {
  .home-subtitle h4 {
    width: 80%;
  }

  .home-scroll-bottom {
    top: 50%;
    transform: translateY(-50%);
    right: -18%;
    opacity: .7;
  }

  .scroll-bottom-line {
    border: .1px solid #fff;
  }
}