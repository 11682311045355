.works-container {
    z-index: 1;
    width: 100%;
    max-width: 108rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: #fff;
    margin-bottom: 20rem;
}

.works-content {
    grid-row-gap: 90px;
    flex-direction: column;
    display: flex;
}

.works-title {
    transform: translateX(-2rem);
}

.works-container h1{
    color: #000;
    font-size: 20rem;
    letter-spacing: -.07em;
    font-weight: 500;
    max-width: 108rem;
    margin: 0 auto;
    padding-top: 15rem;
}

.works-gallery {
    width: 100%;
    grid-column-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-columns: 1fr;
    display: grid;
    
}

.works-gallery-item {
    position: relative;
    grid-row-gap: 14px;
    flex-direction: column;
    display: flex;
    overflow: hidden;
}

.works-gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.gallery-item-title {
    opacity: 0;
}

.gallery-item-title-wrapper h4:last-child{
    color: #fff;
}

.works-gallery-info {
    grid-row-gap: 35px;
    flex-direction: column;
    display: flex;
    grid-area: 1 / 8 / 2 / 11;
}
.works-gallery-info-title{
    font-size: 1.4rem;
    text-transform: uppercase;
}
.works-gallery-info-exp{
    font-size: 2rem;
    line-height: 140%;
    letter-spacing: -.04em;

}

.works-gallery-item-1 {
    grid-area: 1 / 1 / 2 / 7;
}

.works-gallery-item-2 {
    grid-area: 2 / 8 / 3 / 11;
    align-self: start;
    margin: -100px 0;
}

.works-gallery-item-3 {
    grid-area: 3 / 2 / 4 / 6;
}

.works-see-all {
    grid-area: 4 / 2 / 5 / 4;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    display: flex;
    cursor: pointer;
}


.works-see-all h1 {
    font-size: 1.6rem;
    position: relative;
    letter-spacing: -.03em;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.works-see-all h1::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #000;
    bottom: -3px;
    right: 0;
    transition: width .3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.works-see-all h1:hover::after {
    width: 0;
    transition: width .3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.works-gallery-item-4{
    grid-area: 4 / 5 / 5 / 11;
    margin-top: 100px;
    margin-bottom: -58px;
}

@media screen and (min-width: 1921px) {
    .works-container {
        max-width: 150rem;
    }

    .works-title {
        transform: translateX(-10rem);
    }

    .works-gallery-info-exp{
        font-size: 3rem;
    }

    .works-gallery-info-title{
        font-size: 2rem;
        text-transform: uppercase;
    }

    .works-see-all h1 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 768px) {
    html{
        font-size: 8px;
    }

    .works-container{
        grid-row-gap: 40px;
        padding: 5rem;
    }

    .works-title {
        display: block;
    }

    .works-gallery{
        max-width: 500px;
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        flex-direction: column;
        display: flex;
    }

    .works-gallery-info{
        grid-row-gap: -400px;
        order: -1;
    }

    .gallery-item-title {
        opacity: 1;
    }

    .works-see-all {
        order: 1;
    }

    .works-gallery-item-2 {
        grid-area: 2 / 8 / 3 / 11;
        align-self: start;
        margin:  0;
    }

    .works-gallery-item-4 {
        grid-area: 4 / 5 / 5 / 11;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}