.img-wrapper-container {
  position: relative;
  width: 100%;
  height: 150vh;
  overflow-x: clip;
  background-color: black;
}

.img-wrapper-content {
  position: sticky;
  position: -webkit-sticky;
  top: 0%;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.projects-number-section {
  height: 80vh;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.img-wrapper-text {
  position: absolute;
  top: 20%;
  transform: translateX(150%);
  z-index: 9;
}

.img-wrapper-text h1 {
  font-size: 20rem;
  color: #fff;
  white-space: nowrap;
  letter-spacing: -0.07em;
  font-weight: 500;
}

.img-wrapper-gallery {
  width: 100%;
  height: 100%;
}

.img-wrapper-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.projects-number-section-content {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transform: translateY(-15rem);
}

.projects-number-section-left {
  max-width: 50rem;
}
.projects-number-section-left p {
  letter-spacing: -0.03em;
  font-size: 2.5rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.realized-projects-text p {
  letter-spacing: -0.03em;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.8);
}
.projects-number-section-right {
  display: flex;
  gap: 50px;
}

.realized-projects-number,
.team-member-number {
  letter-spacing: -0.03em;
  font-size: 9rem;
  line-height: 100%;
  font-weight: 500;
  text-align: center;
}

.realized-projects-text p,
.team-member-text p {
  letter-spacing: -0.03em;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.8);
}
.projects-number-bottom {
  width: 70%;
  margin: 0 auto;
}

.projects-number-bottom h3 {
  letter-spacing: -0.05em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 7rem;
  font-weight: 500;
  line-height: 120%;
}

@media screen and (min-width: 1921px) {
  .realized-projects-text p,
  .team-member-text p {
    font-size: 2.5rem;
  }

  .realized-projects-number,
  .team-member-number {
    font-size: 12rem;
  }

  .projects-number-section-left p {
    font-size: 3.5rem;
  }


.projects-number-bottom h3 {
  font-size: 9rem;
  width: 80%;
  margin: 0 auto;
}
 
}

@media screen and (max-width: 768px) {
  .img-wrapper-gallery {
    height: 100%;
  }

  .img-wrapper-text {
    top: 30%;
    transform: translateX(50%);
  }

  .img-wrapper-text h1 {
    font-size: 10rem;
  }

  .projects-number-section-content {
    transform: translateY(-10rem);
    padding: 2rem;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }

  .projects-number-section-left p {
    font-size: 2.5rem;
    width: 70%;
    text-align: center;
    margin: 0 auto;
  }

  .projects-number-bottom h3 {
    font-size: 40px;
    text-align: left;
  }
}

@media screen and (max-width: 425px) {
  .img-wrapper-text h1 {
    font-size: 8rem;
  }

  .projects-number-section-content {
    transform: translateY(-5rem);
    padding: 2rem;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }

  .img-wrapper-text {
    top: 30%;
    transform: translateX(100%);
  }

  .projects-number-section-left p {
    font-size: 1.5rem;
    width: 70%;
    text-align: center;
    margin: 0 auto;
  }

  .projects-number-bottom h3 {
    font-size: 30px;
    text-align: left;
  }
}
