.about-container{
    width:100%;
    height: 130vh;
    background-color: #fff;
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items:center;
    padding-top: 20rem;
}
.about-main-container{
    width: 60%;    
}
.about-main-container img{
    height:7rem;
    margin-bottom: 4rem;
}
.about-main-container-texts p{
    letter-spacing: -.04em;
    font-size: 4.4rem;
    line-height: 1.6;
    margin: 5rem 0 2rem 0;
}

.about-main-container-texts h3{
   font-size: 2rem;
    color: #0d0d1286;
    font-weight: 400;
}

@media screen and (min-width: 1921px){

    .about-container{
        width:100%;
        height: 100vh;
    }

    .about-main-container-texts p{
        font-size: 6rem;
        margin-bottom: 5rem;
    }

    .about-main-container-texts h3{
        font-size: 3rem;
     }
}

@media screen and (max-width: 768px){

    .about-container{
        padding-top: 10rem;
        height: 100vh;
        margin-top: 10rem;
    }
    .about-main-container{
        width: 80%;
    }
    .about-main-container-texts p{
        font-size: 3rem;
    }
    .about-main-container-texts h3{
        font-size: 1.5rem;
    }
    
}